const api = {
  baseUrl: process.env.REACT_APP_EVENTX_API_BASE_URL ?? '',
};

const zoom = {
  clientId: process.env.REACT_APP_ZOOM_CLIENT_ID ?? '',
  sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY ?? '',
  sdkSecret: process.env.REACT_APP_ZOOM_SDK_SECRET ?? '',
};

export const config = {
  api,
  zoom,
};
