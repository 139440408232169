import { config } from "../config";

import {
  ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from "../constant";

export async function login({ token, isRefresh = false }) {
  if ((!localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY) || isRefresh) && token) {
    await fetch(`${config.api.baseUrl}/integrations/zoom/oauth/${isRefresh ? 'refresh' : 'token'}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: token,
    })
      .then((response) => response.json())
      .then(({ success, data }) => {
        if (success) {
          const { access_token, refresh_token } = data;
          localStorage.setItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);
          localStorage.setItem(ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token);
          window.location.href = window.location.origin;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export async function logout() {
  await fetch(`${config.api.baseUrl}/integrations/zoom/oauth/revoke`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      token: localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY)
    }),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });

  localStorage.removeItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  localStorage.removeItem(ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY);

  window.location.href = window.location.origin;
}
