import React from 'react';

import queryString from 'query-string';
import styled, { createGlobalStyle } from 'styled-components';

import { config } from './config';

import Meeting from './Meeting';
import User from './User';
import EventxLogoWhite from './components/EventxLogoWhite';

import { login } from './utils/auth';

import { ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY } from "./constant";

const GlobalStyle = createGlobalStyle`
  #content_container {
    overflow: hidden auto;
  }
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  img {
    margin-top: 16px;
  }
`;

const App = () => {
  const { query } = queryString.parseUrl(window.location.href);

  if (query.code && !query.link) {
    login({
      token: JSON.stringify({
        code: query.code,
        redirectUri: window.location.origin,
      })
    });

    return null;
  }

  const renderView = () => {
    if (localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY)) {
      return <User />;
    } else {
      return (
        <a style={{ marginTop: 8 }} href={`https://zoom.us/oauth/authorize?client_id=${config.zoom.clientId}&response_type=code&redirect_uri=${window.location.origin}`} rel="noopener noreferrer">
          <img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" height="32" alt="Add to ZOOM" />
        </a>
      )
    }
  }

  return (
    <Container>
      <GlobalStyle />
      <ContentContainer>
        {query.link ? (
          <Meeting />
        ) : (
          <>
            <EventxLogoWhite />
            {renderView()}
          </>
        )}

      </ContentContainer>
    </Container>
  );
}

export default App;
