import React from "react";

const EventxLogoWhite = () => (
  <img
    alt="logo-white"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASgAAAArCAMAAAD8OuUxAAAARVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////9SnXPCAAAAFnRSTlMAn99Av2AgEO+Az38woHCQX4+vUG+wdzBHPAAABBRJREFUeF612m2ToyAMB/AI8qClutU7v/9HvWll53/nLAnXpHnHTAX8NZVNXHpFWkZ3cDER0fmJeQvUH37AvF0x/nPpPETqiL1/lYGIxnOQx4c0e9q3b5fX+FcGCQdVw/VSeUZJgnpUuZvdKoDCMHFO+1MJUAkXylDYvRw3BkSCwrWL2SqAQrjYOTOAO6EgZe8EqHggvKEToCDVNzPG3VDy7uu9KqBW/lYQ/lBAQUrOVIr5HMwDFzsRrc/BVrmc5IRH5vA/cfv74nuVSg0lpHnunT8Q0TLgTiDVcqr7p3Au5KkzYt19EJxC9WfSQVJ2VKUGKaFWeiemSjGntpPz2BJz20yq5MQDOOT1+1BpFg6PFZCWUnDC/jPzQ2K/xaknoQKpoCjWE+SLAz0iqaVEJ3rB/SayTCkkFCmhqORzscJA5eYmnrFyUnecIQj6BSfEG7kbhZTCUkENRQF75kARvfcGqavAV3XKkXRQtGKeBmUtd0gPRRN+HQZQshScCmmhUuZ4cWZ4CyjaTqnHZ6DSfGD+i5MaiiYeIiKdDaCSw9FnCwUpzF9QEGihkFJjA2A4oaINFMWMrSuhZKnvQWDUFSmlSygJwOPhagqFJyqkYoaTFgo/h9EooSSABbWMEkqWKhktCy0UTu1d/oNUD4UieTSBkqUmTv02MPFT0rf2OP9cvPjHIMXeBhhxNJlCQQolQFkYdaEt5Vt5s3QWL7Gr69UCwNG36KFkKTKEQiHTV7y4QwWFo68YQ2F+SFlCoTbuSqhwaKFoRy3zESiHTdhC0YaUEhNq00OhllFAyX9M3UX1u2PCt2vjx0/FS/i53en4WFgAtPFUULJTLAp1ppCJlwRuHuNHITYEALTxDKEADieXiyUUChmpeMEN6aDQxrOGGtDAey2SoyUUChmmeDGFQhtPASU4JYcjwwgKKcUklC0U2niWUA84Ec1YwQoKNZhnEsoUCkefCoprkC8YGUIRamMkVI6fg0IbTwHFORFNGFtBoZAJTDVsC4VaRgHFODWklFCojRPzKs8WCm08BRTnRJBKhlDIIhQvn4VCG88ACk7i676sel2C2hjFy6eh0MZTQ301nkcrpC7do6hOKSTUp6HQxtNC4QVe6yXWfMVz70lhzrwgoVpQ2QgKbTwVFJwK87ovXTLCDUGIyDRyEJ67M3GNkLqg8AbLvQO1n2uNcGKqP7ecALCTIgiFDJvnW+civgHFfjtjPxQMEJ7pJyCIktNAoZBhn3WrMRTt8s5kKFwtS9WaXANFk5hQVBRQ0qKOFFBwEqTqP9GpoGjGKStj6qGupYYCyhVqR1ovUER+U0HVGUfu6AzOGIrC8/vJQ6K3ocaF+Ij3DKgaxQuRiMPnP4AV5EXw2UJCRF/YRf0zYmMjwrVXlz9smTAnJUAcdQAAAABJRU5ErkJggg=="
    width={200}
  />
);

export default EventxLogoWhite;
