import React, { useCallback, useEffect, useState } from "react";
import validator from 'validator';

import { Button, Snackbar, TextField } from '@material-ui/core';
import styled from 'styled-components';

import { config } from "./config";

import { login, logout } from "./utils/auth";

import {
  ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from "./constant";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiTextField-root {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.09);
  }

  .MuiFormLabel-root {
    font-size: 12px;
    color: white !important;
    padding-left: 8px;
  }

  .MuiInputBase-input {
    font-size: 12px;
    color: white !important;
    padding-left: 8px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const User = () => {
  const [userData, setUserData] = useState({
    id: null,
    firstName: null,
    lastName: null,
    email: null,
  });

  const [meetingUrl, setMeetingUrl] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const buildUrl = useCallback((url, userName, userEmail) => {
    const locale = 'en';

    const zoomUrl = new URL(url);
    const zoomParams = zoomUrl.searchParams;

    zoomParams.delete('userName');
    zoomParams.delete('userEmail');

    const cleanedZoomUrl = zoomUrl.toString();

    const encodedZoomUrl = encodeURIComponent(cleanedZoomUrl);

    const finalUrl = `${window.location.origin}/?link=${encodedZoomUrl}&userName=${encodeURIComponent(userName)}&userEmail=${encodeURIComponent(userEmail)}&locale=${locale}`;

    return finalUrl;
  }, []);

  const handleJoinMeeting = useCallback(() => {
    if (!validator.isURL(meetingUrl)) {
      setIsSnackbarOpen(true);
      setSnackbarMessage('Please enter a valid URL');
    } else {
      const formattedUrl = buildUrl(meetingUrl, userData.displayName, userData.email);
      window.open(formattedUrl, '_blank');
    }
  }, [meetingUrl, userData, buildUrl]);

  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
    setSnackbarMessage(null);
  }, []);

  useEffect(() => {
    if (localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY)) {
      const accessToken = localStorage.getItem(ZOOM_ACCESS_TOKEN_LOCAL_STORAGE_KEY);

      fetch(`${config.api.baseUrl}/integrations/zoom/users/me`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          accessToken,
        }),
      })
        .then((res) => res.json())
        .then(({ success, data}) => {
          if (!success && data.message) {
            if (data.message === 'Invalid access token.') {
              logout();
            } else {
              login({
                token: JSON.stringify({
                  refreshToken: localStorage.getItem(ZOOM_REFRESH_TOKEN_LOCAL_STORAGE_KEY)
                }),
                isRefresh: true,
              });
            }

            return null;
          }

          setUserData({
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            displayName: data.display_name,
            email: data.email,
          })
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (userData.firstName) {
    return (
      <Content>
        <h3>Hello, {userData.firstName}.</h3>
        <Form>
          <TextField label="Meeting URL" onChange={(e) => setMeetingUrl(e.target.value)} />
          {meetingUrl && (
            <Button variant="contained" onClick={handleJoinMeeting}>Join Meeting</Button>
          )}
        </Form>
        <Button variant="contained" onClick={logout}>Logout</Button>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Content>
    );
  }

  return null;
}

export default User;
